import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"
import { FaAngleDoubleRight } from "react-icons/fa"
// ...GatsbyImageSharpFluid
const About = () => {
  return (
    <Layout>
      <section id="about" className="section jobs">
        <Title title="Адвокатская деятельность" />
        <div className="jobs-center">
          <div className="btn-container"></div>
          <article className="job-info">
            {/* <h3>Адвокатская деятельность</h3> */}
            {/* <h4>Могилевская коллегия адвокатов</h4> */}
            <div className="job-desc">
              <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
              <p>
                Работаю с 2001-го года в Коллегии Адвокатов Республики Беларусь.
              </p>
            </div>
            <div className="job-desc">
              <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
              <p>
                Специальное разрешение (Лицензия) выдано на основании Решения от
                18.01.2001 № 20 и зарегистрировано в реестре специальных
                разрешений (лицензий) Министерства Юстиции РБ за № 970
              </p>
            </div>
            <div className="job-desc">
              <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
              <p>Стаж работы адвокатом 19 лет.</p>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default About
